.sa-chat-wrapper(style="display: none")
  button.sa-chat-tab(tabindex="0", onclick=`_sa.toggleChat()`)
    div.sa-message-count(aria-hidden="true")=0
    span.sa-message-announce(aria-live="polite")=''
    if chatTabImage 
      img.chat-tab-image(src=chatTabImage, alt=usePhrase('Open Chat'))
    if chatTabText
      span.chat-tab-text=chatTabText
  .sa-chat-slideout
    include ./header.pug
    iframe.sa-chat-iframe(src="", frameborder="0", name="Web Chat", title="Web Chat")